.c-header {
  white-space: nowrap;
  padding: 5px $content-padding;
  border-bottom: 1px solid $color-border--light2;
  // box-shadow: 0 4px 2px -2px gray;
  background: $color-white;
  min-width: 300px;
}

.c-header--title {
  text-align: right;
  height: calc(64px - 13px);
}

.c-header--heading {
  float: left;
  display: inline-block;
  padding-left: 45px;
  padding-top: 6px;
  height: calc(64px - 13px);
  font-family: $font-family;
  font-size: 20px;
  color: $color-text--default;
}

.c-header--buttons {
  height: 43px; // calc(64px - 13px);
  vertical-align: middle;
  line-height: calc(64px - 13px);
  display: inline-block;
  float: right;
}

.c-header-logo {
  background-image: url('../assets/Palladion-Logo-aufBlau-RGB.jpg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 30px;
  margin: 6px 0px 0px 0px;
  width: 150px;
  display: inline-block;
}

@include breakpoint-lg-max {
  .c-header {
    display: none;
  }
}
