$c-textarea--border: 1px;
$c-textarea--border-focus: 2px;

.c-textarea--top-padding {
    height: 12px;
}

.c-textarea--text {
    border-bottom: $c-textarea--border solid $color-border--normal;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 0px 0px 1px 0px;
    min-height: 32px;
    line-height: 15px;

    outline: none;
    box-shadow: none;

    color: $color-text--input;

    transition: all 0.2s;

    display: block;

    caret-color: $color-border--focus;

    &[disabled] {
        border-bottom: $c-textarea--border dashed $color-border--normal;
        background: none;
        color: $color-text--gray-dark;
    }

    &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
        border-bottom: $c-textarea--border-focus solid $color-border--hover;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;
    }

    &:focus {
        border-bottom: $c-textarea--border-focus solid $color-border--focus;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;
    }

    &.o-hyperform--invalid {
        border-bottom: $c-textarea--border-focus solid $color-border--invalid;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        padding-bottom:0px;
    }

    &::placeholder {
        color: $color-text--gray;
        font-weight: 300;
    }
}

.c-textarea--text-wrapper {
    display: block;
    position: relative;
    min-height: 55px;

    textarea {
        display: block;
        position: relative;
        width: 100%;
    }

    label {
        display: block;
        position: absolute;
        left: 0px;
        top: 16px;
        z-index: 10;
        pointer-events: none;
        cursor: text;
        transition: all 0.2s;
        color: $color-text--gray;
        font-weight: 400;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
    }

    textarea:focus ~ label,
    textarea:not(.c-textarea--empty) ~ label,
    textarea[placeholder] ~ label {
        font-size: 9px;
        top: 0px;
    }

    /* has to be in a separate blocks, otherwise firefox ignores the other selectors */
    textarea:-webkit-autofill ~ label {
        font-size: 9px;
        top: 0px;
    }

    textarea:focus ~ label
    {
        color: $color-border--focus;
    }
}

