
.c-dialog--content {
  display: none;
}


.swal2-popup .swal2-content {
  font-size: 1em !important;
}

