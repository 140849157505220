$cell-height: 28px;
$table-border-color: $color-border--normal;
$table--cellpadding: 10px;

.c-table--table {
  display: block;
}

.c-table--tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 1rem 0 1rem 0px;
  .c-table--td,
  .c-table--th {
    flex: 1 1 100px;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 1rem;
  }
}
.c-table--thead .c-table--tr {
  padding: 0;
}

.c-table--th {
  font-weight: bold;
}

.c-table--wrapper {
  .c-table--table {
    width: 100%;
  }

  .c-table--tr {
    border-bottom: 1px solid $table-border-color;
    &:last-child {
      border: none;
    }
  }

  .c-table--th {
    text-align: left;
    height: $cell-height;
    overflow: hidden;

    &.sorting {
      cursor: pointer;

      &:hover {
        color: $color-link--main-hover;
      }
    }
  }

  .c-table--th[data-sortable='1'] {
    cursor: pointer;

    &:hover,
    &.c-table--sorted-asc,
    &.c-table--sorted-desc {
      color: $color-link--main-hover;
      text-decoration: underline;
    }

    &.c-table--sorted-asc:after {
      @include fa-icon();
      content: $fa-var-caret-down;
      margin-left: 10px;
      font-size: 14px;
    }

    &.c-table--sorted-desc:after {
      @include fa-icon();
      content: $fa-var-caret-up;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .c-table--tr:last-child {
    td {
      border-bottom: 0px;
    }
  }

  .c-button--iconbutton {
    line-height: $cell-height;
    height: $cell-height;
  }
}

.c-table--base {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-table--paginate {
  margin-top: 10px;
  margin-bottom: 5px;
  float: right;

  .c-table--paginate-curpage {
    box-sizing: border-box;
    padding: 5px 10px;
    background: white;
    color: $color-main--accent;
    font-weight: bold;
    border: 1px solid $color-main--accent;
    padding: 4px 9px;
  }
  .c-table--paginate-link {
    padding: 5px 10px;
    background: $color-main--accent;
    color: white;
  }
  color: $color-gray--90;
  font-size: 0.6rem;
}

.c-table--btn {
  font-size: 14px;
}

.c-table--icons {
  position: relative;
  right: 0px;
  margin-left: auto;
  display: flex;
}
