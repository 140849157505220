$card-text-padding: 16px;

.c-card {
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  display: inline-block;
}

.c-card--content {
  padding: $card-text-padding;
}
