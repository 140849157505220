$c-navigation--width: $navigation-width;
$c-navigation--background: $color-main--light;
$c-navigation--text-color: $color-text--default;
$c-navigation--modal-color: #000000;
$c-navigation--link-color: $color-text--default;
$c-navigation--spacer-color: $color-spacer;
$c-navigation--action-size: 21px;
$c-navigation--bar-padding: 1rem;
.c-navigation--wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.c-navigation--enabled {
  .c-wrapper--main {
    left: #{$c-navigation--width};
    width: calc(100% - #{$c-navigation--width});
    position: absolute;
  }
}

.c-navigation--modal {
  /* No modal on desktop, mobile see below*/
  display: none;
}

.c-navigation--bar {
  background: $c-navigation--background;
  color: $c-navigation--text-color;
  width: $c-navigation--width;
  height: 100%;
  position: absolute;
  z-index: 1001;
  padding: $c-navigation--bar-padding;
  a {
    text-decoration: none;
  }
  & > a {
    display: block;
    width: 100%;
    padding-bottom: 60%;
    background-image: url('../assets/Palladion-Logo-aufBlau-RGB.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.c-navigation--toplist,
.c-navigation--bottomlist {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid $c-navigation--spacer-color;
  > .c-navigation--item {
    padding: 0px;
    margin: 20px 0px;
  }

  > .c-navigation--item-active a {
    background: $color-white;
    font-weight: bold;
    color: $color-main--accent;
    position: relative;
    overflow: hidden;
  }

  a {
    > span {
      font-family: $font-family;
      font-size: 16px;
      line-height: 30px;
      color: $c-navigation--text-color;
    }
    color: $c-navigation--text-color;

    padding: 0 10px 5px 10px;
    transition: color 0.2s, background-color 0.5s;
    background: $c-navigation--background;
    display: block;
  }
}

.c-navigation--collapsible {
  float: right;
}

.c-navigation--expand {
  @include faicon-before($fa-var-angle-right);
}

.c-navigation--collapse {
  @include faicon-before($fa-var-angle-down);
}

.c-navigation--sublist {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1) -0.1s;

  li {
    a {
      font-family: 'Inter UI';
      font-size: 16px;
      line-height: 30px;
      color: $c-navigation--text-color;
      padding-left: 50px;
      font-size: 14px;
    }
  }
}
.c-navigation--sublist-active {
  max-height: 4000px;
  overflow: hidden;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  transition-delay: 0s;
}

.c-navigation--info-user {
  padding: 18px 20px 5px 20px;
}

.c-navigation--info-action {
  padding: 20px 20px;

  > a {
    color: $c-navigation--text-color;
    padding: 7px 10px;
    border-left: 1px solid $c-navigation--spacer-color;
    font-size: $c-navigation--action-size;
    &:last-child {
      border-right: 1px solid $c-navigation--spacer-color;
    }
  }
}

.c-navigation--btn-settings {
  @include faicon-before($fa-var-cog);
}
.c-navigation--btn-logout {
  @include faicon-before($fa-var-sign-out);
}

@keyframes c-navigation--show-scroll {
  0% {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  100% {
    position: block;
    width: 100%;
    height: 100%;
  }
}

/* For IPad / Mobile */
@include breakpoint-lg-max {
  .c-navigation--burger-btn {
    display: inline-block;
    font-size: 20px;
    padding: 5px 20px 5px 20px;
    margin-left: -20px;
    font-weight: 300;
    cursor: pointer;
    top: 0px;
    left: 10px;
    position: absolute;
    color: $c-navigation--text-color;

    @include faicon-before($fa-var-bars);
  }
  .c-navigation--modal {
    width: 100%;
    background: $c-navigation--modal-color;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1000;
    display: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .c-navigation--bar {
    left: -#{$c-navigation--width};
    transition: left 0.2s;
  }

  .c-navigation--enabled {
    .c-wrapper--main {
      left: 0px;
      transition: left 0.2s;
      width: 100%;
    }
  }

  .c-navigation--inactive {
    animation-name: c-navigation--show-scroll;
    animation-duration: 1s;
  }

  .c-navigation--active {
    position: fixed;
    width: 100%;
    height: 100%;

    .c-navigation--wrapper {
      z-index: 1000;
    }
    .c-navigation--modal {
      display: block;
      opacity: 0.5;
    }
    .c-navigation--bar {
      left: 0px;
    }

    .c-wrapper--main {
      left: #{$c-navigation--width};
    }
  }
}

.c-navigation--bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.c-navigation--left {
  float: left;
  padding: 10px 0px 10px 10px;
  .c-navigation--username {
    font-weight: bold;
    word-break: break-all;
    width: calc(100% - 20px);
  }
}

#c-navigation--logout {
  // cant use $c-navigation-bar-padding here
  width: calc(100% - (2 * 1rem));
  margin-bottom: $c-navigation--bar-padding;
  button {
    color: $color-text--white;
  }
}
