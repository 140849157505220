$panel--padding: 2.5rem;

.c-panel--centervertical {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  > * {
    flex: 1;
  }
}

.c-panel--content {
  margin-top: 2rem;
  // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //   0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;
  min-height: calc(100% - 5px);
  background: $color-white;
  padding: $panel--padding;
}

.c-panel--spacer {
  height: $panel--padding * 2;
  border-bottom: 1px solid $color-border--normal;
}

.c-panel--title {
  color: $color-text--default;
  vertical-align: middle;
  line-height: 32px;
}

.c-panel--title-menu {
  display: inline-block;
  float: right;
  font-size: 14px;
  line-height: 32px;
  color: $color-text--white;

  a:before,
  button:before {
    line-height: 32px;
    color: $color-text--white;
  }

  .c-button--iconbutton {
    height: 32px;
    line-height: 32px;
    padding: 0px;
    width: 32px;
    min-width: inherit;
    margin-right: -7px;
  }
}

/* different styled versions */
.c-panel--auth {
  width: 40%;
  min-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
