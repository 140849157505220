///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 200ms ease-in-out !default;

$content-padding: 10px;

$navigation-width: 180px;

$tablet-width: 768px;
$desktop-width: 1025px;
$fhd-width: 1920px;
$f4k-width: 3840px;

$font-family: Helvetica, sans-serif;
