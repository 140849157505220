@keyframes o-hyperform--fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* hyperform validation message */

.o-hyperform--warning {
  font-family: $font-family;
  font-weight: normal;
  font-size: 9px;
  color: $color-border--invalid;
  animation: o-hyperform--fadein 0.2s;
  height: 10px;
  //position: absolute;
  left: 0px;
  //bottom: -5px;
  max-width: 100%;
}
