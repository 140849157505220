$spinnerHeight: 66px;
$spinnerWidth: 66px;

.c-spinner {
  display: block;
  content:'';
  display: block;
  width: $spinnerWidth;
  height: $spinnerHeight;
  margin: 0 auto;
  transform: translateY(-50%);
  position: relative;

  &:before {
    content:'';
    display: block;
    width: $spinnerWidth;
    height: $spinnerHeight;
  }
}