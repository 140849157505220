a {
  color: $color-link--main;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.c-table--table a {
  color: $color-text--default;
}
