.c-checkbox--box {
  display: none;
}

.c-checkbox--box + .c-checkbox--virtualbox {
  border: 2px solid $color-border--dark;
  width: 20px;
  height: 20px;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  transition: all 0.18s;

  .c-checkbox--virtualbox-check {
    display: block;
    height: 20px;
    @include faicon-before($fa-var-check);
    color: $color-main--default;
    top: -2px;
    left: 1px;
    position: relative;
    opacity: 0;
  }
}

.c-checkbox--box:checked + .c-checkbox--virtualbox {
  border: 2px solid $color-border--dark2;
  .c-checkbox--virtualbox-check {
    opacity: 1;
  }
}