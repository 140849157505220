@import "./components.datetimepicker.lib";
@import "~font-awesome/scss/variables";
@import "~font-awesome/scss/mixins";

.dtp {
    .hidden {
        display: none;
    }
   
   .dtp-calendarweek {
  		color: $color-text--gray-dark !important;
  		border-right: 1px solid $color-border--light2 !important;
  		padding-right: 10px !important;
	}
}

.c-datetimepicker--chevron-right {
    @include fa-icon();
    
    &:before {
        content: $fa-var-chevron-right;
    }
}

.c-datetimepicker--chevron-left {
    @include fa-icon();
    
    &:before {
        content: $fa-var-chevron-left;
    }
}

.c-datetimepicker--chevron-up {
    @include fa-icon();
    
    &:before {
        content: $fa-var-chevron-up;
    }
}

.c-datetimepicker--chevron-down {
    @include fa-icon();
    
    &:before {
        content: $fa-var-chevron-down;
    }
}

.c-datetimepicker--arrow-close {
    @include fa-icon();
    
    &:before {
        content: $fa-var-close;
    }
}

.c-input--datetime-date {
    width: calc(50% - 5px) !important;
    display:inline-block !important;
    margin-right: 5px;
}

.c-input--datetime-time {
    width: calc(50% - 5px) !important;
    display:inline-block !important;
    margin-left: 5px;
}