$c-input--border: 1px;
$c-input--border-focus: 2px;

.c-input--top-padding {
  height: 12px;
}

.c-input--text {
  border: $c-input--border solid $color-border--normal;
  padding: 0px 0px 1px 0px;
  min-height: 45px;
  line-height: 17px;
  outline: none;
  box-shadow: none;
  color: $color-text--input;
  transition: border-color 0.2s;
  display: block;
  caret-color: $color-border--focus;

  &[disabled] {
    border-bottom: $c-input--border dashed $color-border--normal;
    background: none;
    color: $color-text--gray-dark;
  }

  &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
    border-bottom: $c-input--border-focus solid $color-border--hover;
    padding-bottom: 0px;
  }

  &:focus {
    border-bottom: $c-input--border-focus solid $color-border--focus;
    padding-bottom: 0px;
  }

  &.o-hyperform--invalid {
    border-bottom: $c-input--border-focus solid $color-border--invalid;
    padding-bottom: 0px;
  }

  &::placeholder {
    color: $color-text--gray;
    font-weight: 300;
  }
  &[readonly]{
    opacity: 0.4;
   }
}

.c-input--text-wrapper {
  display: block;
  position: relative;

  input {
    display: block;
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 1rem;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    display: block;
    pointer-events: none;
    cursor: text;
    transition: color 0.2s;
    color: $color-text--default;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 4px;
  }
}
.c-input--radio-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65px;
  input {
    display: block;
    padding-right: 5px;
    padding-left: 1rem;
  }

  label {
    font-weight: bold;
    flex: 1 1 65px;
    font-size: 12px;
    color: $color-text--default;
    text-overflow: ellipsis;
    overflow: hidden;
    

  }

}
