.c-footer {
  background: $color-main--default;
  height: 30px;
  width: calc(100% - (2 *#{$content-padding}));
  margin: 10px $content-padding;
  padding: 5px 0px 5px 30px;
  position: absolute;
  bottom: 0px;

  * {
    font-size: 14px;
    font-family: $font-family;
    color: $color-text--white;
  }

  a {
    text-decoration: none;
    padding-right: 30px;
  }
}

.c-footer--copy {
  float: left;
}

.c-footer--navigation {
  float: right;

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li {
      display: inline-block;
    }
  }
}
