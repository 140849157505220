.c-breadcrumbs {
  margin-top: 2rem 0 0 2rem;
  padding: 8px 20px 8px 0;
  height: 32px;

  background: $color-main--background;

  .c-breadcrumbs--crumb {
    display: inline-block;

    color: $color-text--default-light;

    &:after {
      @include fa-icon();
      content: $fa-var-chevron-right;
      width: 6px;
      height: 9px;
      margin: auto 0 auto 10px;
      font-size: 9px;
    }

    &:last-child:after {
      margin-right: 0px;
      content: '';
      display: none;
    }
  }
}
