$tile--padding: 1.5rem;
$width: 700px;
$tile-small-margin: 16px;
.c-tile--wrapper {
  margin-bottom: 2rem;
  // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //   0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: $tile--padding;
  width: $width;
  .c-tile--header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    &-buttons {
      display: flex;
      * {
        margin-left: 1rem;
        width: fit-content;
        word-break: none;
      }
    }
  }
  .c-tile-credits--positive {
    font-weight: bold;
    color: green !important;
  }
  .c-tile-credits--negative {
    font-weight: bold;
    color: black !important;
  }
  .c-tile--info {
    &-row {
      border-bottom: 1px solid $color-border--normal;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      & > span:first-child {
        font-weight: bold;
        & * {
          font-weight: normal;
          font-size: 12px;
          max-width: 70%;
          margin-top: 0.5rem;
        }
      }
    }
    &-row:last-child {
      border: none;
    }
  }
}

.c-tile-small-wrapper {
  width: $width;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .c-tile--wrapper-small {
    flex: 1 1 $width/2 - $tile-small-margin;
    max-width: $width/2 - $tile-small-margin;
    & + & {
      margin-left: $tile-small-margin;
    }
  }
  .c-tile--credits-wrapper {
    margin-top: 8px;
    .c-tile--credits-header {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.5rem;
      &-positive {
        color: $color-good;
      }
      & + div {
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
