@mixin faicon($icon) {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  line-height: 1;
  content: $icon;
}

@mixin faicon-before($icon, $padding: 0) {
  &:before {
    @include faicon($icon);
    padding-right: $padding;
  }
}
