$radio-size: 20px;
.c-radio {
  display: inline-block;
  height: $radio-size;
  width: $radio-size;
  line-height: $radio-size;
}

.c-radio--input {
  display: none;
}

.c-radio--virtualradio {
  cursor: pointer;
  transition: all 0.18s;
  display: inline-block;
  width: $radio-size;
  height: $radio-size;
  font-size: $radio-size;
  @include faicon-before($fa-var-circle-o);

}

.c-radio--input:checked + .c-radio--virtualradio {
  @include faicon-before($fa-var-dot-circle-o);
  color: $color-main--default;
}