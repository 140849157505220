/* snackbar.css */
#snackbar-container {
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 99999;
}
.snackbar {
  overflow: hidden;
  clear: both;
  min-width: 288px;
  max-width: 568px;
  cursor: pointer;
  opacity: 0;
}
.snackbar.snackbar-opened {
  height: auto;
  opacity: 1;
}
@media (max-width: 767px) {
  #snackbar-container {
    left: 0px !important;
    right: 0px;
    width: 100%;
  }
  #snackbar-container .snackbar {
    min-width: 100%;
  }
  #snackbar-container [class="snackbar snackbar-opened"] ~ .snackbar.toast {
    margin-top: 20px;
  }
  #snackbar-container [class="snackbar snackbar-opened"] {
    border-radius: 0;
    margin-bottom: 0;
  }
}

/* material.css */
.snackbar {
  background-color: #323232;
  color: #ffffff;
  font-size: 14px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 0;
  -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  -moz-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}
.snackbar.snackbar-opened {
  padding: 14px 15px;
  margin-bottom: 20px;
  height: auto;
  -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s;
  -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, height 0s linear 0.2s;
  -moz-transform: none;
  -webkit-transform: none;
  transform: none;
}
.snackbar.toast {
  border-radius: 200px;
}

/* custom */
.c-snackbar--error {
  background: $color-snackbar--error;
}

.c-snackbar--info {
  background: $color-snackbar--info;
}
