/* Import font awesome */
$fa-font-path: '../node_modules/font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';
@import '~font-awesome/scss/variables';
@import '~inter-ui/inter.css';

/* set default font */

body {
  font-family: $font-family;
  font-size: 14px;
  color: $color-text--default;
}

table,
p {
  font-family: $font-family;
  font-size: 14px;
  color: $color-text--default;
}

input,
button,
select,
label {
  font-family: $font-family;
  font-size: 14px;
  color: $color-text--default;
}
