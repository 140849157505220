html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  /* from https://www.toptal.com/designers/subtlepatterns/circles-light/ */
  background-color: $color-main--background;
}
