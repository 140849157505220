$button-height: 40px;
.c-button {
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
  font-family: $font-family;
  font-size: 12px;
  width: 100%;
  color: $color-text--white;
  background-color: $color-main--accent;
  padding: 10px 16px 8px 16px;
  height: $button-height;
  line-height: $button-height/2;
  display: block;
  text-align: center;
  &:before {
    font-family: FontAwesome;
  }
  &:hover {
    color: white;
  }
}
#button-cancel {
  background-color: $color-gray--50;
}
.c-button--icontextbutton {
  border: 0px transparent;
  text-decoration: none;
  &:before {
    font-size: 12px;
    display: inline-block;
    position: relative;
    top: 2px;
  }
}

.c-button--iconbutton {
  border: 0px transparent;
  background: transparent;
  padding: 0 16px;
  &:before {
    font-size: 12px;
    display: inline-block;
  }
  color: $color-text--default;
  &:hover {
    color: $color-gray--50;
  }
}

.c-button--flat {
  position: relative;
  display: inline-block;

  text-decoration: none !important;
  border: 0px;
}

.c-button--fullwidth {
  width: 100%;
}

.c-button--hidden-form {
  display: none;
}
