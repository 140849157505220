$color-white: #ffffff;
$color-black: #000000;

$color-gray--40: #444444;
$color-gray--50: #555555;
$color-gray--60: #666666;
$color-gray--90: #999999;
$color-gray--120: #cccccc;
$color-gray--130: #dddddd;
$color-gray--152: #f2f2f2;

$color-main--default: #009ddc;
$color-main--light: #fff;
$color-main--dark: #0a2239;
$color-main--accent: #2424c9;
$color-main--background: #eeeff3;
$color-red--default: #b71c1c;

$color-warning: #cdb932;
$color-error: #ff0000;
$color-good: #66a51e;

$color-555555: #555555;
$color-spacer: #dfe1e8;

$color-text--default-light: #848790;
$color-1a1a1a: #1a1a1a;
$color-606267: #606267;

$color-background--dark: $color-gray--40;

$color-border--light: $color-gray--90;
$color-border--light2: $color-gray--120;
$color-border--dark: $color-gray--60;
$color-border--dark2: $color-gray--40;

$color-border--normal: $color-spacer;
$color-border--focus: $color-main--accent;
$color-border--invalid: $color-red--default;
$color-border--hover: $color-main--accent;

$color-text--default: $color-gray--50;
$color-text--gray: $color-gray--120;
$color-text--gray-dark: $color-gray--90;
$color-text--white: $color-white;
$color-text--input: $color-gray--60;

$color-link--main: $color-main--default;
$color-link--main-hover: $color-main--dark;

$color-button--iconbutton: $color-main--accent;
$color-button--iconbutton-focus: $color-gray--60;
$color-button--iconbutton-active: $color-gray--60;
$color-button--iconbutton-hover: $color-gray--60;

$color-button--flat-background: transparent;
$color-button--flat-text: $color-main--default;

$color-button--flat-background-focus: $color-gray--152;
$color-button--flat-text-focus: $color-main--default;

$color-button--flat-background-active: $color-gray--130;
$color-button--flat-text-active: $color-main--default;

$color-button--flat-background-hover: $color-gray--152;
$color-button--flat-text-hover: $color-main--default;

$color-snackbar--error: $color-red--default;
$color-snackbar--info: $color-gray--40;
