// Phone
$t-breakpoint-xs-max: 479px;

// Phablet
$t-breakpoint-sm-min: 480px;
$t-breakpoint-sm-max: 767px;

// Tablet
$t-breakpoint-md-min: 768px;
$t-breakpoint-md-max: 959px;

// Desktop ~1024
$t-breakpoint-lg-min: 960px;
$t-breakpoint-lg-max: 1279px;

// Desktop ab hd ready
$t-breakpoint-xl-min: 1330px;
$t-breakpoint-xl-max: 1600px;

/* Grid-Offset Definition */
$t-breakpoint-xs-gridoffset: 18px;
$t-breakpoint-sm-gridoffset: 25px;
$t-breakpoint-md-gridoffset: 25px;
$t-breakpoint-lg-gridoffset: 75px;
$t-breakpoint-xl-gridoffset: 100px;

$t-breakpoint-xs-gutter: 24px;
$t-breakpoint-sm-gutter: 24px;
$t-breakpoint-md-gutter: 24px;
$t-breakpoint-lg-gutter: 50px;
$t-breakpoint-xl-gutter: 50px;

@mixin breakpoint-xs-max {
  @media (max-width: #{$t-breakpoint-xs-max}) {
    @content;
  }
}

@mixin breakpoint-sm-min {
  @media (min-width: #{$t-breakpoint-sm-min}) {
    @content;
  }
}
@mixin breakpoint-sm-max {
  @media (max-width: #{$t-breakpoint-sm-max}) {
    @content;
  }
}
@mixin breakpoint-md-min {
  @media (min-width: #{$t-breakpoint-md-min}) {
    @content;
  }
}
@mixin breakpoint-md-max {
  @media (max-width: #{$t-breakpoint-md-max}) {
    @content;
  }
}
@mixin breakpoint-lg-min {
  @media (min-width: #{$t-breakpoint-lg-min}) {
    @content;
  }
}
@mixin breakpoint-lg-max {
  @media (max-width: #{$t-breakpoint-lg-max}) {
    @content;
  }
}
@mixin breakpoint-xl-min {
  @media (min-width: #{$t-breakpoint-xl-min}) {
    @content;
  }
}
@mixin breakpoint-xl-min {
  @media (min-width: #{$t-breakpoint-xl-min}) {
    @content;
  }
}
@mixin breakpoint-xl-max {
  @media (max-width: #{$t-breakpoint-xl-max}) {
    @content;
  }
}
