.c-wrapper--main {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
}

.c-wrapper--content {
  // padding: 0px $content-padding 0px $content-padding;
  flex: 1;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    height: 10px;
  }
}
