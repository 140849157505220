@import '../../node_modules/select2/src/scss/core.scss';

$c-dropdown--border: 1px;
$c-dropdown--border-focus: 2px;

.c-dropdown--container {
  select {
    width: 100%;
  }
}

.select2-selection,
.selection {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.select2 {
  transition: border-color 0.2s;

  &[disabled] {
    border-bottom: $c-input--border dashed $color-border--normal;
    background: none;
    color: $color-text--gray-dark;
  }

  &:hover:not([disabled]):not(:focus):not(.o-hyperform--invalid) {
    border-bottom: $c-input--border-focus solid $color-border--hover;
    padding-bottom: 0px;
  }

  &:focus {
    border-bottom: $c-input--border-focus solid $color-border--focus;
    padding-bottom: 0px;
  }

  &.o-hyperform--invalid {
    border-bottom: $c-input--border-focus solid $color-border--invalid;
    padding-bottom: 0px;
  }

  &::placeholder {
    color: $color-text--gray;
    font-weight: 300;
  }
}

.select2-dropdown--below {
  border: 2px solid $color-main--accent !important;
  border-top: none !important;
}

// Select 2 creates a separate overlay in the html body
.c-dropdown--select2-dropdown {
  border: 0px;
  // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //   0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .select2-results__options[aria-multiselectable='true'] {
    .select2-results__option {
      white-space: nowrap;
      vertical-align: middle;

      &:before {
        vertical-align: middle;
        content: '';
        display: inline-block;
        margin-right: 5px;
        // border: 2px solid $color-border--dark;
        width: 20px;
        height: 20px;
      }
    }

    .select2-results__option[aria-selected='true'] {
      @include faicon-before($fa-var-check);
    }
  }

  .select2-results__option {
    min-height: 32px; // height + padding
    padding: 6px;
  }
}

.select2 {
  border: $c-input--border solid $color-border--normal;
  padding: 0px 5px 1px 1rem;
  min-height: 45px;
  line-height: 17px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.select2-selection__rendered {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 30px !important;
  text-overflow: ellipsis;
  overflow: visible;
  max-height: 100%;
  white-space: nowrap;

  >span {
    display: inline-block !important;
    float: none !important;
  }
}

.c-dropdown--wrapper.c-dropdown--wrapper-multiple {
  height: auto;

  .c-dropdown--container {
    height: auto;

    .select2-container {
      .select2-selection--multiple {
        height: auto;

        .select2-selection__rendered {
          white-space: normal;
          overflow: auto;

          >span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-radius: 0px;
  height: 32px;
}

.c-dropdown--wrapper {
  display: inline-block;
  position: relative;
  height: 95px;
  width: 100%;
  // padding: 1rem 0;


  // This is the select2 element
  .c-dropdown--container {
    display: block;
    position: relative;
    padding: 12px 0px 1px 0px;
    height: 43px;
    line-height: 17px;
  }

  // The label
  label {
    font-size: 12px;
    font-weight: bold;
    display: block;
    pointer-events: none;
    cursor: text;
    transition: all 0.2s;
    color: $color-text--default;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 4px;
  }

  .c-dropdown--container-focus~label,
  .c-dropdown--container-not-empty~label {
    font-size: 9px;
    top: 0px;
  }

  .c-dropdown--container-focus~label {
    // color: $color-border--focus;
  }
}

$c-dropdown--actionbar-height: 30px;

.c-dropdown--action-bar {
  height: $c-dropdown--actionbar-height;

  >div {
    display: inline-block;
    width: calc(100% / 3);
    height: $c-dropdown--actionbar-height;
    line-height: $c-dropdown--actionbar-height;
    vertical-align: middle;
    font-size: 16px;
    text-align: center;

    &:hover {
      color: $color-main--default;
      cursor: pointer;
    }
  }
}

.c-dropdown--action-bar-all {
  @include faicon-before($fa-var-check-circle-o);
}

.c-dropdown--action-bar-none {
  @include faicon-before($fa-var-close);
}

/* For Autocomplete, we are using the MultipleSelection template. We need to overwrite the "Tag"-like Style */
.c-dropdown--container[data-ddstyle='autocomplete'] {
  .select2-selection__choice {
    border: 0px !important;
    background: none !important;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.select2-selection__choice {
  border: 1px solid $color-main--accent !important;
  background-color: $color-main--accent !important;
  padding: 0.4rem !important;
  color: white;
  border-radius: 0px !important;
  margin: 0 1rem 0 0 !important;
  font-size: 12px;
  display: flex;

  &__remove {
    margin-right: 0.2rem !important;
    padding: 0 0.5rem;
    color: white !important;
  }

  &__remove:hover {
    color: $color-gray--60 !important;
  }
}

.select2-container--default.select2-container--disabled {
  .select2-selection {
    background: none;
    color: #999999;
  }

  .c-dropdown--selection-div {
    color: #999999;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-main--accent;
}

.select2-results__option[aria-disabled] {
  // display: none;
}

.select2-selection__choice[title=''] {
  display: none !important;
  margin: 0 !important;
}