$tabs--tabbar-height: 32px;

.c-tabs {
  .c-tabs--nav {
    height: $tabs--tabbar-height;
    line-height: $tabs--tabbar-height;
    vertical-align: middle;
    text-align: left;

    white-space: nowrap;

    //background: $color-gray--152;

    margin: 0px;
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;

    > li {
      display: inline-block;

      height: $tabs--tabbar-height;
      line-height: $tabs--tabbar-height;
      vertical-align: middle;
      text-align: center;

      //width: 160px;
      padding-left: 10px;
      padding-right: 10px;

      font-weight: 500;
      a {
        color: $color-text--gray-dark;
        &:hover {
          color: $color-text--default;
        }
      }

      &:hover {
        cursor: pointer;
      }
      &.c-tabs--active {
        font-weight: normal;
        a {
          color: $color-text--default;
        }
      }

      font-weight: normal;
      font-family: $font-family;

      &:before {
        margin-right: 5px;
        font-family: FontAwesome;
      }
    }
  }
  .c-tabs--tab {
    display: none;
    &.c-tabs--active {
      display: block;
    }
  }
}

.c-tabs--tabbar-indicator {
  border-bottom: 1px solid $color-border--normal;
  //background: $color-gray--152;
  padding-left: 10px;
  padding-right: 10px;
}

.c-tabs--tabbar-indicator-inner {
  height: 2px;
  position: relative;
  background: $color-main--default;
  transition: all 0.4s;
}
