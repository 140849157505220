.c-forms--formtr {
  display: table-row;
}

.form_two_column {
  display: flex;
  & div,
  .c-button {
    flex: 1 1 100px;
  }
  & div + div,
  & .c-button + .c-button {
    margin-left: 1rem;
  }
  & .c-button + .c-button {
    flex: 2 2 100px;
  }
}
